<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    spinner-variant="primary"
  >
    <b-card
      v-if="programs.length < 1"
      class="text-center"
    >
      You don't have any pending invites!
    </b-card>
    <section class="grid-view">
      <b-card
        v-for="(program, i) in programs"
        :key="i"
        :title="program.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <div
          class="px-2 py-1 bg-primary"
        >
          <b-card-title class="text-white">
            {{ program.title }}
          </b-card-title>
          <b-card-sub-title>
            <span class="text-light">{{ program.type }}</span>
          </b-card-sub-title>
        </div>
        <b-card-body class="p-2">
          <b-card-text>
            Description: <br> <em>{{ program.description }}</em>
            <hr>
            Begin date: {{ program.begin_date }}
            <br> Capacity: {{ program.capacity }}
            <br> Industry: {{ program.industry }}
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            :to="`/mentor-portal/programs/${program.id}`"
            class="btn btn-wishlist btn-light"
            variant="light"
          >
            <span>View Details</span>
          </b-link>
          <b-link
            v-if="program.users_associationtables[0].status.toLowerCase() === 'invited'"
            class="btn btn-wishlist btn-primary"
            variant="light"
            @click="selectedIndex = i; $bvModal.show('respond-modal')"
          >
            <span>Respond</span>
          </b-link>
          <b-link
            v-else
            :class="{
              'btn-success': program.users_associationtables[0].status.toLowerCase() === 'accept',
              'btn-danger': program.users_associationtables[0].status.toLowerCase() === 'reject'
            }"
            class="btn btn-wishlist"
            disabled
            variant="light"
            @click="selectedIndex = i; $bvModal.show('respond-modal')"
          >
            <span>{{ program.users_associationtables[0].status + 'ed' }}</span>
          </b-link>
        </div>
      </b-card>
      <b-modal
        id="respond-modal"
        cancel-title="Reject"
        ok-title="Accept"
        title="Respond to Invitation"
        @cancel="updateResponse(false)"
        @ok="updateResponse(true)"
      >
        <span
          v-if="selectedIndex !== null"
        >
          Would you like to join <strong class="text-primary">{{ programs[selectedIndex].title }}</strong> as a <strong
            class="text-primary"
          >{{ programs[selectedIndex].users_associationtables[0].role }}</strong>?
        </span>
      </b-modal>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardSubTitle, BCardText, BCardTitle, BLink, BModal, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
    BModal,
  },
  data() {
    return {
      selectedIndex: null,
      programs: [],
      userID: getUserData().id,
      mutationLoading: false,
    }
  },
  methods: {
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.programs[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    programs: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
        {
            programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
